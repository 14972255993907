import { RefObject, useEffect, useRef } from 'react'

type IntersectionCallback = () => void
type UseOnEnterViewport = (
  ref: RefObject<Element>,
  callback?: IntersectionCallback,
  refDependency?: unknown,
  threshold?: number
) => void

export const useOnEnterViewport: UseOnEnterViewport = (ref, callback, refDependency, threshold) => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const callbackRef = useRef<IntersectionCallback | undefined>(callback)

  useEffect(() => {
    if (!callback) return
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (!callback) return
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && callbackRef.current) {
          callbackRef.current()
          callbackRef.current = undefined // Prevent subsequent calls to the callback
        }
      },
      { threshold: threshold || 1 }
    )

    if (ref.current) {
      observerRef.current.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observerRef.current?.unobserve(ref.current)
      }
    }
  }, [callback, ref, refDependency, threshold])
}

import { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { useWarehouseContext } from '@obeta/data/lib/stores/useWarehouseContext'
import {
  PersonalRecommendationsResponse,
  RecommendationProduct,
  PersonalRecommendationGrouping,
  PersonalRecommendationsGroup,
  PersonalRecommendations,
} from '@obeta/models/lib/models/Product/RecommendationProduct'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'

export const RECOMMENDED_PRODUCTS_QUERY = gql`
  query getAllProductsBySapId($sapIds: [String!]!, $warehouseIds: [String!]!) {
    getProducts(sapIds: $sapIds) {
      isForSale
      isSendable
      sapId
      dehaId
      supplierId
      title
      isCutProduct
      isTecSelect
      isTopseller
      minimumAmount
      images {
        url
        width
      }
      supplierImageData {
        large
        sapId
      }
      imageData {
        images {
          large
        }
      }
      stock(warehouseIds: $warehouseIds) {
        sapId
        amount
        unit
        warehouseId
      }
      replacementProducts {
        dehaId
      }
      prices {
        strikeThroughPrice
        catalogPrice
        netPrice
        tecSelect
        currency
        listPrice
      }
    }
  }
`

export const GET_PERSONAL_RECOMMENDATION_QUERY = gql`
  query getPersonalRecommendationQuery($group: String) {
    getPersonalRecommendation(group: $group) {
      recommendations {
        sap_id
        grouping
      }
      recommendationsGroupings {
        grouping
      }
    }
  }
`

const mapProductsWithGrouping = (
  products: ProductAggregate[],
  recommendations: PersonalRecommendations[]
): RecommendationProduct[] => {
  const sapIdToGroupingMap = recommendations.reduce((map, recommendation) => {
    map[recommendation.sap_id] = recommendation.grouping
    return map
  }, {} as Record<string, PersonalRecommendationsGroup>)

  return products.map((product) => ({
    ...product,
    grouping: sapIdToGroupingMap[product.sapId],
  }))
}

const ITEMS_PER_REQUEST = 50

const chunkArray = <T>(array: T[], size: number): T[][] => {
  const result: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }
  return result
}

export const useGetAllRecommendedProducts = ({
  hasCachedRecommendationsAndIsNotExpired,
  group,
}: {
  hasCachedRecommendationsAndIsNotExpired: boolean
  group?: PersonalRecommendationsGroup
}) => {
  const [recommendedProducts, setRecommendedProducts] = useState<RecommendationProduct[]>([])
  const apolloClient = useApolloClient()
  const [isFetching, setIsFetching] = useState(false)
  const [recommendationsGroupings, setRecommendationsGroupings] = useState<
    PersonalRecommendationGrouping[]
  >([])
  const { selectedStore } = useUserSelectedStore()
  const selectedStoreId = selectedStore?.id
  const { warehouseId } = useWarehouseContext()

  useEffect(() => {
    if (hasCachedRecommendationsAndIsNotExpired) return

    const getRecommendedProducts = async () => {
      try {
        setIsFetching(true)

        const recommendationsResponse = await apolloClient.query<{
          getPersonalRecommendation: PersonalRecommendationsResponse
        }>({
          query: GET_PERSONAL_RECOMMENDATION_QUERY,
          variables: { group },
        })

        const personalRecommendations =
          recommendationsResponse.data.getPersonalRecommendation.recommendations.map(
            (recommendation) => recommendation.sap_id
          )

        if (personalRecommendations.length) {
          const warehouseIds: string[] = [warehouseId]
          if (selectedStoreId) warehouseIds.push(selectedStoreId)

          // FYI: We use batched requests because handling large queries (50+ items) is complex and may trigger a request complexity error.
          const sapChunks = chunkArray(personalRecommendations, ITEMS_PER_REQUEST)

          const productsResponsePromises = sapChunks.map((chunk) =>
            apolloClient.query({
              query: RECOMMENDED_PRODUCTS_QUERY,
              variables: {
                sapIds: chunk,
                warehouseIds,
              },
            })
          )

          const productsResponses = await Promise.all(productsResponsePromises)

          const allProducts = productsResponses.flatMap((response) => response.data.getProducts)

          const recommendations =
            recommendationsResponse.data.getPersonalRecommendation.recommendations

          const recommendationsGroupings =
            recommendationsResponse.data.getPersonalRecommendation.recommendationsGroupings

          setRecommendationsGroupings(recommendationsGroupings)

          const productsWithGrouping = mapProductsWithGrouping(allProducts, recommendations)

          setRecommendedProducts(productsWithGrouping)
        } else {
          setRecommendedProducts([])
        }
      } catch (err) {
        handleError(err)
      } finally {
        setIsFetching(false)
      }
    }

    getRecommendedProducts()
  }, [hasCachedRecommendationsAndIsNotExpired, apolloClient, selectedStoreId, warehouseId, group])

  return { products: recommendedProducts, isFetching, recommendationsGroupings }
}

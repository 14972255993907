import React, { useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useGetAllRecommendedProducts } from '../../hooks/useGetAllRecommendedProducts'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useUserSelectedStore } from '@obeta/data/lib/hooks/useUserSelectedStore'
import { useBreakpoints } from '@obeta/data/lib/hooks'
import { useShoppingCartsV2 } from '@obeta/data/lib/hooks/useShoppingCartsV2'
import { ProductsSwiper } from '../../../articleDetailsPage/ProductsSwiper'
import { ArticleCarouselCard } from '@obeta/components/lib/product-card'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { TabFilters } from '@obeta/components/lib/tab-filters/TabFilters'
import { useStartPageData } from '@obeta/data/lib/hooks/useStartPageDataProvider'
import { CommonSkeleton } from '@obeta/components/lib/sidebar-skeletons/SidebarSkeletons'
import {
  PersonalRecommendationsGroup,
  RecommendationProduct,
} from '@obeta/models/lib/models/Product/RecommendationProduct'
import { datadogRum } from '@datadog/browser-rum'
import { useOnEnterViewport } from '@obeta/data/lib/hooks/useOnEnterViewPort'
import styles from './RecommendationsPanel.module.scss'

const AllRecommendationGroup = 'all'

const formatProductData = (product: RecommendationProduct) => ({
  sap_id: product.sapId,
  grouping: product.grouping,
  stock_online: product.stock?.reduce((sum, stock) => sum + stock.amount, 0),
  price: product.prices?.netPrice,
})

const sendClickEvent = (product: RecommendationProduct) => {
  datadogRum.addAction('PERSONAL_REC_CLICK', {
    PRODUCT: formatProductData(product),
  })
}

const sendRenderEvent = (cachedRecommendations: RecommendationProduct[]) => {
  datadogRum.addAction('PERSONAL_REC_RENDER', {
    PRODUCTS: cachedRecommendations.map(formatProductData),
  })
}

export const RecommendationsPanel = () => {
  const { user, isLoggedIn } = useUserDataV2()
  const { selectedStore: store } = useUserSelectedStore()
  const { mobile, tablet, tabletAll } = useBreakpoints()
  const { carts, activeCart } = useShoppingCartsV2()
  const { t } = useTranslation()
  const [activeGroup, setActiveGroup] = useState<
    PersonalRecommendationsGroup | typeof AllRecommendationGroup
  >(AllRecommendationGroup)
  const prevActiveGroup = useRef(AllRecommendationGroup)
  const personalRecommendationContainer = useRef<HTMLDivElement>(null)
  const hasSentRenderEvent = useRef(false)

  const {
    recommendations: cachedRecommendations,
    timeLimit,
    setTimeLimit,
    setRecommendations,
  } = useStartPageData()

  const timeLimitExpired = timeLimit && timeLimit < dayjs(new Date()).unix()

  const { products, recommendationsGroupings, isFetching } = useGetAllRecommendedProducts({
    hasCachedRecommendationsAndIsNotExpired:
      !timeLimitExpired &&
      cachedRecommendations.recommendations.length > 0 &&
      // We don't need to cache it when change active group
      prevActiveGroup.current === activeGroup,
    group: activeGroup === AllRecommendationGroup ? undefined : activeGroup,
  })

  // We use cached data only when there is no actual data available to avoid layout shifts during redirection
  const recommendations = products.length ? products : cachedRecommendations.recommendations

  const handleIntersection = () => {
    if (!hasSentRenderEvent.current) {
      sendRenderEvent(cachedRecommendations.recommendations)
      hasSentRenderEvent.current = true
    }
  }

  useOnEnterViewport(
    personalRecommendationContainer,
    handleIntersection,
    recommendations.length,
    0.3
  )

  useEffect(() => {
    /*
      We cache data only on the first load for the "all" tab,
      and during further navigation between tabs, the cache is not updated.
      The cache will be refreshed only after it expires or when the page is reloaded.
    */
    const isCacheMissForAllTab =
      cachedRecommendations.recommendations.length === 0 && activeGroup === AllRecommendationGroup

    if (isCacheMissForAllTab) {
      setRecommendations({ recommendations: products, recommendationsGroupings })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, recommendationsGroupings, activeGroup])

  useEffect(() => {
    if (timeLimit && !timeLimitExpired) return
    setTimeLimit(dayjs(new Date()).add(1, 'hours').unix())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterTabsOptions = [
    {
      label: t(`START.RECOMMENDATIONS_OPTIONS.${AllRecommendationGroup}`),
      value: AllRecommendationGroup,
      disabled: isFetching,
    },
    ...cachedRecommendations.recommendationsGroupings.map((recommendation) => ({
      label: t(`START.RECOMMENDATIONS_OPTIONS.${recommendation.grouping}`),
      value: recommendation.grouping,
      disabled: isFetching,
    })),
  ]

  const onChangeRecommendationGroup = (
    newActiveGroup: PersonalRecommendationsGroup | typeof AllRecommendationGroup
  ) => {
    prevActiveGroup.current = activeGroup
    setActiveGroup(newActiveGroup)
  }

  if (!recommendations.length) return null

  return (
    <div className={styles.recommendationsPanel} ref={personalRecommendationContainer}>
      <div className={styles.topSection}>
        <div className={styles.titleWrapper}>
          <Typography variant="headline3Bold" className={styles.title}>
            {t('START.YOUR_RECOMMENDATIONS')}
          </Typography>
        </div>

        <div className={styles.filtersWrapper}>
          <TabFilters<string>
            spaceBetween={24}
            options={filterTabsOptions}
            onChangeTab={onChangeRecommendationGroup}
            activeTab={activeGroup || AllRecommendationGroup}
          />
        </div>
      </div>

      {isFetching ? (
        <CommonSkeleton count={3} height={32} width={20} />
      ) : (
        <ProductsSwiper
          withNavigateButtons={!mobile}
          cardWrapperClassName={clsx(styles.cardWrapper)}
          elementsPerRow={mobile ? 'auto' : tablet ? 2 : 4}
          productElements={recommendations.map((product) => (
            <ArticleCarouselCard
              onClick={() => sendClickEvent(product)}
              key={product.sapId}
              productId={product.sapId}
              product={product}
              selectedStore={store}
              user={user}
              tablet={tabletAll}
              mobile={mobile}
              isLoggedIn={isLoggedIn}
              carts={carts}
              selectedCart={activeCart}
            />
          ))}
        />
      )}
    </div>
  )
}

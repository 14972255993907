import React, { useEffect } from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import styles from './OrdersSection.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import {
  ORDERS_INPUT_FOR_START_PAGE,
  SEARCH_QUERY_START_PAGE,
  useOrderSearch,
} from '@obeta/data/lib/hooks/useOrderSearch'
import { useTranslation } from 'react-i18next'
import { DarkButton } from '@obeta/components/lib/custom-button/CustomButton'
import { OrdersSection } from '../startPage/OrdersSection'
import { Section } from '../startPage/Section'
import { getOrderListDateStrings } from '@obeta/utils/lib/orders-helpers'
import { OrderForListPage } from '@obeta/models/lib/schema-models/order-list'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import {
  DEFAULT_ORDER_LIST_FILTERS,
  OrderListURLSearchParams,
} from '@obeta/models/lib/models/Orders'
import { SearchOrdersStartPageQuery } from '@obeta/schema'
import { updateURLSearchParams } from '@obeta/utils/lib/virtualized-list'
import { useStartPageData } from '@obeta/data/lib/hooks/useStartPageDataProvider'

export const OrdersSectionContainer: React.FC = () => {
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()
  const history = useHistory()

  const { checkHasOrdersInArrears, hasOrdersInArrears, dateOfOldestOrderInArrears, queryOrders } =
    useOrderSearch()

  const { orders: cachedOrders, setOrders, timeLimit, setTimeLimit } = useStartPageData()

  const timeLimitExpired = timeLimit && timeLimit < dayjs(new Date()).unix()

  const hasCachedOrdersAndIsNotExpired = cachedOrders.length > 0 && !timeLimitExpired

  useEffect(() => {
    if (timeLimit && !timeLimitExpired) return
    setTimeLimit(dayjs(new Date()).add(1, 'hours').unix())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (hasCachedOrdersAndIsNotExpired) return

      const data = await queryOrders<SearchOrdersStartPageQuery>(
        ORDERS_INPUT_FOR_START_PAGE,
        SEARCH_QUERY_START_PAGE
      )

      if (data && data.searchOrders.orders.length > 0) {
        setOrders(data.searchOrders.orders as OrderForListPage[])
      }
    }
    getData()
    checkHasOrdersInArrears()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onShowOrdersInArrears = () => {
    const params: OrderListURLSearchParams = {
      ...getOrderListDateStrings(dateOfOldestOrderInArrears, dayjs().format('YYYY-MM-DD')),
      // Reset remaining filters
      itemStates: 'InArrears',
      orderType: DEFAULT_ORDER_LIST_FILTERS.orderType.toString(),
      projectName: DEFAULT_ORDER_LIST_FILTERS.projectName.toString(),
      purchaser: DEFAULT_ORDER_LIST_FILTERS.purchaser.toString(),
      searchTerm: DEFAULT_ORDER_LIST_FILTERS.searchTerm,
      shippingType: DEFAULT_ORDER_LIST_FILTERS.shippingType.toString(),
      supplierIds: DEFAULT_ORDER_LIST_FILTERS.supplierIds.toString(),
    }
    updateURLSearchParams(params, history, '/orders-list')
  }

  return cachedOrders.length > 0 ? (
    <div className={styles['orders-section-wrapper']}>
      <div className={styles['orders-section-background']} />
      <Section
        className={clsx(styles.ordersContainer)}
        title={t('START.YOUR_LAST_ORDERS')}
        content={<OrdersSection orders={cachedOrders} />}
        rightAdornment={
          !mobile &&
          hasOrdersInArrears && (
            <DarkButton onClick={() => onShowOrdersInArrears()}>
              {t('START.SHOW_ALL_ORDERS_IN_ARREARS')}
            </DarkButton>
          )
        }
        rightAdornmentText={t(!mobile ? 'START.SHOW_MORE_ORDERS' : 'START.SHOW_MORE')}
        rightAdornmentLinkTo={ShopRoutes.OrdersList}
      />
    </div>
  ) : null
}
